import api from "../http-request";

export const getListReservation = async (params) =>
  api.get(`api/customer/reservation/list`, { params });
export const getDetailReservation = async (id) =>
  api.get(`api/customer/reservation/detail/${id}`);
export const updatStatusReservation = async (id, data) =>
  api.put(`api/customer/reservation/update-status/${id}`, data);
export const createBillReservation = async (id, data,) =>
  api.post(`api/customer/reservation/create-bill/${id}`, data, { responseType: 'arraybuffer' });
export const createReceiptReservation = async (id, data) =>
  api.post(`api/customer/reservation/create-receipt/${id}`, data, { responseType: 'arraybuffer' });

export const getPaymentMethodByBookingProxy = async () =>
  api.get(`api/v2/rental-spaces/booking-proxy/payment-method`);

export const checkLimitPerMonthBookingReceiptApi = async (params) =>
  api.get(`api/v2/reservation/check-limit-per-month-booking-proxy`, { params });
