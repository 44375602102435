<template>
  <v-app>
    <v-main>
      <Suspense>
      <router-view />
      </Suspense>
      <LoadingScreen v-if="isLoading" class="overlay" />
    </v-main>
  </v-app>
</template>

<script setup>
import { watch } from "vue";
import LoadingScreen from "@/admin/components/Loading/LoadingScreen.vue";
import useStore from "@/client/store/Customer";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

const store = useStore();
const { isLoading } = storeToRefs(store);

const route = useRoute();
const url = window.location.protocol + '//' + window.location.hostname;
const domain = window.location.hostname;

function createTitleDesc(routeInstance){
  //domin twitter
  document.querySelector("meta[id='domain-tw']").setAttribute('content', domain)
  //url twitter
  document.querySelector("meta[id='url-tw']").setAttribute('content', url)
  //url facebook
  document.querySelector("meta[id='url-fb']").setAttribute('content', url)

  // op image
  if(routeInstance.meta.op_img){
     var setOpImg = routeInstance.meta.op_img;
     setOpImg = url + setOpImg;
     document.querySelector("meta[id='op-image']").setAttribute('content', setOpImg)
   } else {
   // default
   document.querySelector("meta[id='op-image']").setAttribute('content', url + "/logo-seo/time-sharing-logo.png")
  }

    //titleを設定する処理
    if(routeInstance.meta.title){
      var setTitle = routeInstance.meta.title;
      // setTitle = setTitle.substr(0, 30);
      document.title = setTitle;
    } else {
      // デフォルト
      document.title = '【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング'
    }

    // メタタグのdescription設定処理
    if(routeInstance.meta.description){
      var setDesc = routeInstance.meta.description;
      // setDesc = setDesc.substr(0, 133);
      document.querySelector("meta[name='description']").setAttribute('content', setDesc)
    } else {
      // デフォルト
      document.querySelector("meta[name='description']").setAttribute('content', 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！')
    }

    //title seo facebook
    if(routeInstance.meta.title_fb){
      var setTitleFB = routeInstance.meta.title_fb;
      // setTitleFB = setTitleFB.substr(0, 94);
      document.querySelector("meta[id='title-fb']").setAttribute('content', setTitleFB)
    } else {
      // default
      document.querySelector("meta[id='title-fb']").setAttribute('content', "【公式】貸し会議室・レンタルスペースならタイムシェアリング")

    }

    // description facebook
    if(routeInstance.meta.description_fb){
      var setDescFB = routeInstance.meta.description_fb;
      // setDescFB = setDescFB.substr(0, 199);
      document.querySelector("meta[id='desc-fb']").setAttribute('content', setDescFB)
    } else {
      // default
      document.querySelector("meta[id='desc-fb']").setAttribute('content', 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！')
    }

    // image facebook
    if(routeInstance.meta.img_fb){
      var setImgFB = routeInstance.meta.img_fb;
      setImgFB = url + setImgFB;
      document.querySelector("meta[id='image-fb']").setAttribute('content', setImgFB)
    } else {
    // default
    document.querySelector("meta[id='image-fb']").setAttribute('content', url + "/logo-seo/time-sharing-logo.png")
    }

    //title seo twitter
    if(routeInstance.meta.title_tw){
      var setTitleTW = routeInstance.meta.title_tw;
      // setTitleTW = setTitleTW.substr(0, 69);
      document.querySelector("meta[id='title-tw']").setAttribute('content', setTitleTW)
    } else {
      // default
      document.querySelector("meta[id='title-tw']").setAttribute('content', "【公式】貸し会議室・レンタルスペースならタイムシェアリング")
    }

    // description twitter
    if(routeInstance.meta.description_tw){
      var setDescTW = routeInstance.meta.description_tw;
      // setDescTW = setDescTW.substr(0, 199);
      document.querySelector("meta[id='desc-tw']").setAttribute('content', setDescTW)
    } else {
      // default
      document.querySelector("meta[id='desc-tw']").setAttribute('content', 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！')
    }

    // image twitter
    if(routeInstance.meta.img_tw){
      var setImgTW = routeInstance.meta.img_tw;
      setImgTW = url + setImgTW;
      document.querySelector("meta[id='image-tw']").setAttribute('content', setImgTW)
    } else {
    // default
    document.querySelector("meta[id='image-tw']").setAttribute('content', url + "/logo-seo/time-sharing-logo.png")
    }

    // canonical
    const link = document.querySelector("link[rel='canonical']")
      ? document.querySelector("link[rel='canonical']")
      : document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute(
      "href",
      'https://time-sharing.jp' + location.pathname
    );
    document.head.appendChild(link);

}
createTitleDesc(route);
watch(route, (routeInstance) => {
  createTitleDesc(routeInstance);
});

</script>

<style lang="scss" scoped>
.overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.53);
  z-index: 99999;
}
</style>
