export const ROUTE_NAME = {
  ADMIN: {
    LIST_SPACE: "AdminListSpace",
    CREATE_SPACE: "AdminCreateSpace",
    EDIT_SPACE: "AdminEditSpace",
    ADD_SPACE_LOCATION: "AdminAddSpaceLocation",
    SPACE_CATEGORY: "AdiminSpaceCategory",
    SPACE_CATEGORY_DETAIL: "AdiminSpaceCategoryDetail",
    SPACE_LOCATION: "AdminSpaceLocation",
    LOGIN: "AdminLogin",
    SPACE_PLAN: "AdminPlan",
    SPACE_EDIT_PLAN: "AdminPlanEdit",
    SPACE_SUPPLY: "AdminSupply",
    SPACE_EDIT_SUPPLY: "AdminSupplyEdit",
    MUNICIPALITIES: "adminMunicipalities",
    MUNICIPALITIES_EDIT: "adminMunicipalitiesDetail",
    SPACE_TAGS: "adminTagSpace",
    SPACE_TAGS_DETAIL: "adminTagSpaceEdit",
    EDIT_LOCATION: "AdminEditLocation",
    NEWS: "AdminNews",
    ADD_NEWS_DETAIL: "AdminNewsDetail",
    ADD_NEWS_EDIT: "AdminNewsEdit",
    CAMPAIGN: "AdminCampaign",
    CAMPAIGN_DETAIL: "AdminCampaignDetail",
    CAMPAIGN_EDIT: "AdminCampaignEdit",
    BLOG_LIST: "AdminBlogList",
    BLOG_ADD_NEW: "AdminBlogAddNew",
    BLOG_SHARING_TAG: "AdminBlogSharingTag",
    BLOG_SHARING_TYPE: "AdminBlogSharingType",
    BLOG_TYPE_DETAIL: "AdminBLogTypeDetail",
    ROLE_BANNER: "AdminRoleBanner",
    ROLE_BANNER_CREATE: "AdminCreateRoleBanner",
    ROLE_BANNER_UPDATE: "AdminUpdateRoleBanner",
    BLOG_TAG_DETAIL: "AdminBlogTag",
    BLOG_DETAIL: "AdminDetailBlog",
  },
};
export const MENU_LIST = [
  {
    title: "スペース",
    value: "User",
    routeName: "",
    children: [
      {
        title: "投稿一覧",
        value: "AllPost",
        routeName: ROUTE_NAME.ADMIN.LIST_SPACE,
      },
      // {
      //   title: "新規追加",
      //   value: "AddNew",
      //   routeName: ROUTE_NAME.ADMIN.CREATE_SPACE
      // },
      // {
      //   title: '市区町村',
      //   routeName: ROUTE_NAME.ADMIN.ADD_SPACE_LOCATION,
      // },
      {
        title: "スペースカテゴリー",
        routeName: ROUTE_NAME.ADMIN.SPACE_CATEGORY,
      },
      {
        title: "プラン",
        routeName: ROUTE_NAME.ADMIN.SPACE_PLAN,
      },
      {
        title: "無料備品",
        routeName: ROUTE_NAME.ADMIN.SPACE_SUPPLY,
      },
      {
        title: "ロケーション",
        routeName: ROUTE_NAME.ADMIN.SPACE_LOCATION,
      },
      {
        title: "市区町村",
        routeName: ROUTE_NAME.ADMIN.MUNICIPALITIES,
      },
      {
        title: "タグ",
        routeName: ROUTE_NAME.ADMIN.SPACE_TAGS,
      },
    ],
  },
  {
    title: "ニュース",
    value: "News",
    routeName: ROUTE_NAME.ADMIN.NEWS,
    children: [],
  },
  {
    title: "キャンペーン",
    value: "Campaign",
    routeName: ROUTE_NAME.ADMIN.CAMPAIGN,
    children: [],
  },
  {
    title: "ロールバナー",
    value: "RoleBanner",
    routeName: ROUTE_NAME.ADMIN.ROLE_BANNER,
    children: [],
  },
  {
    title: "ブログ",
    value: "Blog",
    routeName: "",
    children: [
      {
        title: "ブログ 一覧",
        value: "BlogList",
        routeName: ROUTE_NAME.ADMIN.BLOG_LIST,
      },
      {
        title: "新規追加",
        value: "AddNew",
        routeName: ROUTE_NAME.ADMIN.BLOG_ADD_NEW,
      },
      {
        title: "シェアリングタグ",
        value: "SharingTag",
        routeName: ROUTE_NAME.ADMIN.BLOG_SHARING_TAG,
      },
      {
        title: "シェアリングタイプ",
        value: "SharingType",
        routeName: ROUTE_NAME.ADMIN.BLOG_SHARING_TYPE,
      },
    ],
  },
];
export const TYPE_USER = {
  USER: "user",
  ADMIN: "admin",
};

export const STATUS_FILTER = [
  {
    value: "all",
    text: "全て",
    count: 0,
  },
  {
    value: "published",
    text: "公開済み",
    count: 0,
  },
  {
    value: "drafts",
    text: "下書き",
    count: 0,
  },
  {
    value: "private",
    text: "プライベート",
    count: 0,
  },
];

export const SPACE_LIST_COLUMN = [
  {
    type: "title",
    text: "タイトル",
  },
  {
    type: "location",
    text: "ロケーション",
  },
  {
    type: "spaceCategory",
    text: "スペースカテゴリ",
  },
  {
    type: "author",
    text: "編集者",
  },
  {
    type: "date",
    text: "日付",
  },
];
export const BANNER_LIST_COLUMN = [
  {
    type: "title",
    text: "タイトル",
  },
  // {
  //   type: 'location',
  //   text: 'ロケーション',
  // },
  // {
  //   type: 'spaceCategory',
  //   text: 'スペースカテゴリ',
  // },
  {
    type: "bannerType",
    text: "タイプ",
  },
  {
    type: "date",
    text: "日付",
  },
];
export const SPACE_DISPLAY_TAG = [
  {
    type: "instant-booking",
    label: " 即時予約",
    value: false,
  },
  {
    type: "caterable",
    label: " ケータリング可能",
    value: false,
  },
  {
    type: "sterilization",
    label: " 除菌グッズ設置",
    value: false,
  },
  {
    type: "temp-detector-installed",
    label: " 検温機設置",
    value: false,
  },
  {
    type: "air-purifier",
    label: " 空気清浄機設置",
    value: false,
  },
  {
    type: "window-openable",
    label: " 窓開放可能",
    value: false,
  },
  {
    type: "door-openable",
    label: " 扉開放可能",
    value: false,
  },
  {
    type: "cleaning-by-staff",
    label: " スタッフによる毎回清掃",
    value: false,
  },
];
