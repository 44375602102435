import api from '../http-request'


// business-group
export const getBusinessGroupApi = async () => api.get(`api/business/group`);
export const getBusinessGroupDetailApi = async (id) => api.get(`api/business/group/${id}`);

export const handleCreateBusinessGroupApi = async (data) =>
    api.post(`api/business/group`, data);

export const handleUpdateBusinessGroupApi = async (id, data) =>
    api.put(`api/business/group/${id}`, data);

export const deleteBusinessGroupApi = async (id) =>
    api.delete(`/api/business/group/${id}`);

export const getListGroupJoined = async () =>
    api.get(`api/business/group/list-joined`);
export const checkInGroup = async (groupId) =>
    api.get(`api/business/group/check-in-group/${groupId}`);

// reservation
export const getBusinessReservationsApi = async (params) =>
    api.get(`api/business/reservations`, { params });
export const getBusinessReservationRemainingAmountApi = async (params) =>
    api.get(`api/business/reservations/remaining-amount`, {params});

// list staff
export const getBusinessStaffApi = async (params) =>
    api.get(`api/business/staff`, { params });
export const getBusinessStaffByIdApi = async (staffId) =>
    api.get(`api/business/staff/${staffId}`);

export const handleBusinessInviteStaffApi = async (data) =>
    api.post(`api/business/staff`, data);

export const handleUpdateBusinessStaffByIdApi = async (id, data) =>
    api.put(`api/business/staff/${id}`, data)
export const handleUpdateBusinessStaffRoleApi = async (id, data) =>
    api.post(`api/business/staff/change-role/${id}`, data);

export const deleteBusinessStaffByIdApi = async (id) =>
    api.delete(`api/business/staff/${id}`);

// customer info
export const getBusinessDetailCustomer = async (params) =>
    api.get(`api/business/detail-with-customer`, { params });

export const handleUpdateBusinessInfoApi = async (data) =>
    api.put(`api/business/update`, data)

export const getListUploadedBillBusiness = async (params) =>
    api.get(`api/business/group/upload-bill/list`, { params });

export const downloadBill = async (billId) =>
    api.get(`api/business/download/${billId}`, { params: { type: "invoice" }, responseType: "blob" })

export const downloadReceipt = async (billId) =>
    api.get(`api/business/download/${billId}`, { params: { type: "receipt" }, responseType: "blob" })

//ChangeMasterModal
export const getBusinessAdminListApi = async () => api.get(`api/business/admin`);
export const handleUpdateBusinessAdminApi = async (id, data) =>
    api.put(`api/business/admin/${id}`, data);
export const handleUpdatePaymentMethodApi = async (data) =>
    api.put(`api/business/payment-method/update`, data);

//credit card
export const getBusinessCreditCardListApi = async () => api.get(`api/business/credit-card/list`);
export const handleCreateCreditCardForGroupApi = async (data) =>
    api.post(`api/business/credit-card/add`, data);
export const updateBusinessCardAPi = async (cardSeq, data) =>
    await api.patch(`api/business/credit-card/update/${cardSeq}`, data);
