export default {
  CUSTOMER: {
    REGISTER: 'api/customer/auth/customer-manage',
    LOGIN: 'api/customer/auth/login',
    UPDATE_EMAIL: 'api/customer/auth',
    RECOVERY: 'api/customer/auth/reset-password',
    INFO_CUSTOMER: 'api/customer/get-info-customer',
    UPDATE_INFO_CUSTOMER: 'api/customer/update-customer',
    UPDATE_INFO_REGISTERED: 'api/customer/update-info-registered',
    GET_CUSTOMER_EMAIL: 'api/get-customer-email',
    UPDATE_INFO_CARD_CUSTOMER: 'api/customer/update-info-card',
    LIST_TOUR_OF_CUSTOMER: 'api/customer/get-list-tour-of-customer',
    GET_DETAIL_TOUR_CUSTOMER: 'api/customer/get-detail-tour-of-customer',
    GET_LIST_TOUR_REPLY: 'api/customer/tours/list_reply',
    GET_INFO_SOCIAL: 'api/customer/auth/get-info-social',
    UPDATE_NEW_EMAIL: 'api/customer/update-new-email',
    VERIFY_CODE_NEW_EMAIL: 'api/customer/verify-code-new-email',
    CANCEL_UPDATE_NEW_EMAIL: 'api/customer/cancel-update-new-email',
    REMOVE_PROVIDER: 'api/customer/auth/socialplus/remove-provider',
    MERGE_PROVIDER: 'api/customer/auth/socialplus/merge-user-socialplus',
  },
}
