export const ROUTER_PATH = {
  //ROUTER_CUSTOMER
  HOME: "/",
  LOGIN: "/customer/login",
  REGISTERED: "/customer/registered",
  SIGNUP: "/customer/signup",
  SIGNUP_COMPLETE: "/customer/signup/complete",
  VERIFICATION_CODE: "/customer/verification",
  UPDATE_INFO: "/customer/registered/update-info",
  REGISTERED_SUCCESS: "/customer/registered-success",
  REGISTER: "/customer/register",
  RECOVERY_REQUEST: "/customer/recovery-request",
  USE_GUIDE: "/use-guide",
  SPECIFIED_COMMERCIAL: "/customer/specified-commercial",
  MEMBER_AGREEMENT: "/customer/member-agreement",
  APPLY: "/customer/apply",
  MY_PAGES: "/pages",
  DETAIL: "/detail",
  PANORAMA: "/panorama",
  RESERVATIONS: "reservations",
  TOUR_REQUEST: "tour-request",
  TOUR_REQUEST_DETAIL: "tour-request/:id",
  OBSERVATION_DETAIL: "space/tour/view",
  INQUIRY: "inquiry",
  INQUIRY_DETAIL: "inquiry/:id",
  PROFILE: "profile",
  CREDIT_CARD: "credit-card",
  RESERVATIONS_DETAIL: "reservations/view",
  CUSTOMER_PAYMENT: "customer-payment",
  SPACE_BOOKING_CALENDAR: "/space-booking-calendar-old",
  CONTENT_LOCATION_SPACE: "/contents/location/kanto",
  CONTENT_LOCATION_HEADER: "/contents/location/kanto",
  SPACE_TOUR: "/space/tours",
  RESEARCH_SPACE: "/search",
  RESEARCH_SPACE_LOCATION: "/search-space-location",
  CONTACT_IQUIRIES: "/inquiries",
  RESERVATIONS_REQUEST_INPUT: "/reservations-request-input",
  RESERVATIONS_REQUEST_SUCCESS: "/reservations-request-success",
  RESERVATIONS_REQUEST_CONFIRM: "/reservations-request-confirm",
  CONTENT_CASE: "/contents/case",
  CONTENT_CATEGORY: "/contents/case/category",
  CAMPAIGN_DISCOUNT: "/campaign/discount",
  CAMPAIGN_DISCOUNT202308: "/campaign/discount202308",
  // UN_DESCRIPTION: "leave",
  FEATURE_PAGE: '/feature',
  FOR_PARTY: '/forparty',

  LP_HOW_TO_USE: "/lp/how-to-use",
  LP_CATERING: "/lp/catering",
  LP_SPACE_SAFETY: "/lp/compensation",
  LP_CONCIERGE: "/lp/concierge",
  LP_CORPORATE_USE: "/lp/corporate-use",
  LP_GRADE_PARK_SHIBUYA: "/forparty/focus001",
  LP_GLADE_PARK_OMOTESANDO: "/forparty/focus002",
  LP_EBISU_SHOW_ROOM: "/forparty/focus003",
  LP_GLADE_PARK_SHINJUKU: "/forparty/focus004",
  ELLARE: "/forparty/focus005",
  LP_MACE_SENDAGAYA: "/forparty/focus006",
  LP_MACE_IKEBUKURO: "/forparty/focus007",
  LP_LOUNGE_R_TERRACE_SHIBUYA: "/forparty/focus008",
  LP_MACE_MINAMIAOYAMA: "/forparty/focus009",

  PARTY: {
    SCENE001: "/forparty/scene001",
    SCENE002: "/forparty/scene002",
    SCENE003: "/forparty/scene003",
    SCENE004: "/forparty/scene004",
    SCENE005: "/forparty/scene005",
    SCENE006: "/forparty/scene006",
  },

  BUSINESSACCOUNT: {
    INVITE: "/business-account/invite",
    REGISTER: "/business-account/register",
    REGISTER_SUCCESS: "/business-account/register-success",
    MASTER_DETAIL: "/business-account/master-detail",
    MASTER_RESERVATION: "/business-account/master-reservation-list",
    MASTER_GROUP: "/business-account/master-group-list",
    MASTER_DOWNLOAD: "/business-account/master-download",
    MASTER_USER: "/business-account/master-user-list",
    GENERAL_DETAIL: "/business-account/general-detail",
    GENERAL_RESERVATION: "/business-account/general-reservation-list",
    GENERAL_USER_LIST: "/business-account/general-user-list",
    GENERAL_DOWNLOAD: "/business-account/general-download",
  },

  MEMBER_AGREEMENT_CUSTOMER: "/customer/member-agreement-customer",
  NEWS_DETAIL: "/news/detail",
  NEWS_LIST: "/news/list",
  NOT_FOUND: "/not-found",
  SPACE_BOOKING_CALENDAR_NEW: "/space-booking-calendar",
  RESERVATION_INFORMATION: "/reservation-information",
  RESERVATION_INFORMATION_SUCCESS: "/reservation-information/success",
  RESERVATION_INFORMATION_FAIL: "/reservation-information/fails",
  RESERVATION_INFORMATION_SUCCESS_LIST: "/reservation-information/success/list",
  BROWSING_HISTORY: "history",
};
export const STATUS_CODE = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  TooManyRequests: 429,
  ValidationFailed: 422,
  InternalServerError: 500,
};
export const RENTAL_SPACE_STATUS = {
  published: "published",
  archived: "archived",
};
export const TYPE_USER = {
  USER: "user",
  ADMIN: "admin",
};

export const USER_STATUS = {
  CUSTOMER_STATUS_DEACTIVE: 0,
  CUSTOMER_STATUS_ACTIVE: 1,
  CUSTOMER_STATUS_UNCONFIRM: 2,
};

export const PROFILE_FIELD_REQUIRED = [
  "business_structure",
  "company_name",
  "company_name_kana",
  "first_name",
  "last_name",
  "first_name_kana",
  "last_name_kana",
  "gender",
  "year",
  "month",
  "day",
  "phone_number",
  "address",
];

export const ROUTE_NAME = {
  CUSTOMER: {
    HOME: "home-timesharing",
    TOUR_REQUEST: "tour-request",
    TOUR_REQUEST_DETAIL: "tour-request-detail",
    INQUIRY: "inquiry",
    INQUIRY_DETAIL: "inquiry-detail",
    LOGIN: "customer-login",
    REGISTER: "customer-register",
    RECOVERY_REQUEST: "customer-recovery-request",
    MY_PAGE: "customer-my-page",
    RESERVATIONS: "customer-reservation",
    SPACE_TOUR: "customer-space-tour",
    SPACE_BOOKING_CALENDAR: "customer-space-calendar",
    SPACE_DETAIL: "customer-space-detail",
    SPACE_DETAIL_PREVIEW: "customer-space-detail-preview",
    SPACE_PANORAMA: "customer-space-detail-panorama",
    RESERVATION_REQUEST_INFO: "customer-reservation-request-info",
    CREDIT_PRE_RESERVED: "customer-credit-pre-reserved",
    RESERVATIONS_REQUEST_INPUT: "customer-reservation-request",
    RESERVATIONS_REQUEST_SUCCESS: "customer-reservation-request-success",
    CONTENT_LOCATION_HEADER: "/contents/location/kanto",
    CONTENT_LOCATION_LIST: "/customer-content-lst",
    CONTENT_CASE: "/contents/case",
    CONTENT_CASE_LIST: "/content-case-list",
    CONTENT_CASE_FILTER_LOCATION_LIST: "content_case_filter_location_list",
    CONTENT_CASE_DETAIL_SPACE_LIST: "content-case-detail-space-list",
    CUSTOMER_CHOOSE_PAYMENT_METHOD: "customer_choose_payment_method",
    CANCELLATION_POLICY: "cancellation_policy",
    TERM_OF_USE: "term_of_use",
    PROFILE: "customer-update-profile",
    USE_GUIDE: "/use-guide",
    SPACE_BOOKING_CALENDAR_NEW: "customer-space-calendar",
    RESERVATION_INFORMATION: "reservation-information",
    RESERVATION_INFORMATION_SUCCESS: "reservation-information-success",
    RESERVATION_INFORMATION_FAIL: "reservation-information-fails",
    RESERVATION_INFORMATION_SUCCESS_LIST: "reservation-information-success-list",
    SIGNUP: "customer-signup",
    SIGNUP_COMPLETE: "customer-signup-complete",
    VERIFICATION_CODE: "customer-verification",
    FEATURE_PAGE: "/feature_page",
    BROWSING_HISTORY: "history",
    FOR_PARTY: "/forparty",
  },

  LP: {
    LP_GLADE_PARK_SHINJUKU: "/forparty/focus004",
    HOW_TO_USE: "/lp/how-to-use",
    CATERING: "/lp/catering",
    SPACE_SAFETY: "/lp/compensation",
    CONCIERGE: "/lp/concierge",
    CORPORATE_USE: "/lp/corporate-use",
    GRADE_PARK_SHIBUYA: "/forparty/focus001",
    EBISU_SHOW_ROOM: "/forparty/focus003",
    MACE_IKEBUKURO: "/forparty/focus007",
    GLADE_PARK_OMOTESANDO: "/forparty/focus002",
    LP_GLADE_PARK_OMOTESANDO: "/forparty/focus002",
    MACE_MINAMIAOYAMA: "/forparty/focus009",
    LOUNGE_R_TERRACE_SHIBUYA: "/forparty/focus008",
    MACE_SENDAGAYA: "/forparty/focus006",
    ELLARE: "/forparty/focus005",
  },

  PARTY: {
    SCENE001: "/party/scene001",
    SCENE002: "/party/scene002",
    SCENE003: "/party/scene003",
    SCENE004: "/party/scene004",
    SCENE005: "/party/scene005",
    SCENE006: "/party/scene006",
  }
};

export const NESTED_ROUTE = {
  "tour-request": [ROUTE_NAME.CUSTOMER.TOUR_REQUEST_DETAIL],
  inquiry: [ROUTE_NAME.CUSTOMER.INQUIRY_DETAIL],
};

export const CONTACT_INFO = {
  URL: "https://adval.jp/",
  TEL: "81368008931",
  CONCIERGE_INQUIRIES:
    "https://6e32cadd.form.kintoneapp.com/public/feb83a2091843be98a24c6d8952f76768a36692fa5ae64003cf3081698b665f0",
};
export const PAYMENT_STATUS = {
  CARD_CHARGED: "card-charged",
  TRANSFER_SCHEDULE: "transfer-schedule",
  DEPOSITED: "deposited",
  RESERVATION_FREE_OF_CHARGE: "reservation-free-of-charge",
  RESERVATION_BY_PROXY_SELECT_METHOD: "reservation-by-proxy-selecting-customer-payment-method",
};

export const PROXY_RESERVATION_TYPE = {
  web: "Web予約",
  new_apply: "電話予約(通常)",
  extending: "電話予約(延長・追加)",
};

export const PHONE_NUMBER_REGEX = /^\+?(?:\d{11}|\d{3}\d{3}\d{4}|\d{2}\d{4}\d{4}|\d{3}\d{4}\d{4})$/

export const FAQ_TYPE = {
  NORMAL: "normal",
  STATION: "station",
}
export const TYPE_VIEW = {
  DETAIL: "detail",
  BOOKING: "booking",
};