<template>
  <div class="register-page">
    <Header />
    <div class="body">
      <h2 class="title">TIME SHARING会員規約</h2>
      <div class="content">
        <div class="mb-16">
          <div>
            本規約は、株式会社あどばる（以下「当社」という）が運営する貸し会議室・レンタルスペース（以下「会場」という）の予約サイト「TIME
            SHARING」（以下「本サービス」という）の利用に関して、利用者が遵守すべき事項を定めたものです。
          </div>
          <div>
            利用者は、本サービスの利用に際し、本規約のほか、会場ごとに定める利用規約を遵守するものとします。
          </div>
          <div>
            当社は、利用者が本サービスを利用した場合、当該利用者が本規約に同意したものとみなします。
          </div>
        </div>
        <div class="mb-48">
          <div class="text-bold">《利用について》</div>
          <ul class="list-unstyled">
            <li>
              利用者は、本サービスを利用するに際し、本規約の内容を十分に確認のうえ利用するものとします。
            </li>
            <li>
              利用者は、本サービスを利用するためには、本規約に同意の上、TIME
              SHARING会員（以下「会員」という）として登録しなければなりません。
            </li>
          </ul>
        </div>
        <div class="mb-48">
          <div class="text-bold">《会員登録について》</div>
          <ul class="list-unstyled">
            <li>
              会員は第三者にログインID、パスワードなどを貸与及び譲渡をしてはなりません。
            </li>
            <li>
              会員は、自ら登録した会員情報を他人に知られることがないよう、自己責任で管理するものとします。
            </li>
            <li>
              会員は、登録情報に変更が生じた場合には、遅滞なく会員が自ら変更事項を当社に通知するものとします。
            </li>
            <li>
              会員が未成年者である場合は、親権者など法定代理人の同意（本規約への同意を含む）を得たうえで本サービスを利用するものとする。また、本規約に同意した時点で未成年者であった会員が、成年に達した後に本サービスを利用した場合、未成年者であった間の利用行為を追認したものとみなします。
            </li>
            <li>
              <p>
                当社は、会員が次の各号に該当すると判断した場合には、事前に通知することなく、当該会員に対し、本サービスの利用停止、当該会員資格の取消を行うことができるものとし、これにより当該会員に何らかの損害が生じたとしても、当社は一切責任を負わないものとします。
              </p>
              <ol class="ml-24">
                <li>会員に法令や本規約等に違反する行為があった場合</li>
                <li>会員に本サービスの利用に関して不正行為があった場合</li>
                <li>
                  一定回数以上のログイン失敗など会員のセキュリティを確保するために必要な場合
                </li>
              </ol>
            </li>
            <li>
              会員が、本サービスの最終ログインから起算し3年間に一度も本サービスへログインを行わなかった場合、当社は、事前に通知することなく当該会員の会員資格を取り消すことができるものとします。
            </li>
            <li>
              会員が退会を希望する場合、当該会員本人が所定の退会手続きに従い、退会するものとします。
            </li>
          </ul>
        </div>
        <div class="mb-48">
          <div class="text-bold">《反社会的勢力排除》</div>
          <div class="mb-16">
            <p>
              利用者は、申込者、申込団体、申込責任者、担当者ならびに催物関係者に反社会的勢力該当者がいないことを表明いたします。
            </p>
            <p>
              申込み後にその表明が虚偽の申告であることが判明した場合は、催告なしで会員資格を取り消され、利用者は一切異議を申し立てず、また賠償ないし補償を求めないとともに、これにより損害が生じた場合も当社は如何なる責任も負いません。
            </p>
          </div>
          <div class="mb-16">
            <div class="text-bold">＜反社会的勢力とは＞</div>
            <div class="text-bold">① 下記のいずれかに該当する者又は団体</div>
            <ol class="ml-24 mb-16">
              <li>暴力団</li>
              <li>暴力団員</li>
              <li>暴力団準構成員</li>
              <li>暴力団関係企業</li>
              <li>総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等</li>
              <li>その他前各号に準ずる者</li>
            </ol>
            <div class="text-bold">
              ② 下記のいずれかに該当する行為を行った者又は団体
            </div>
            <ol class="ml-24">
              <li>暴力的な要求行為</li>
              <li>法的な責任を超えた不当な要求行為</li>
              <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
              <li>
                風説を流布し、偽計又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
              </li>
              <li>その他前各号に準ずる行為</li>
            </ol>
          </div>
        </div>
        <div class="mb-48">
          <div class="text-bold">《利用者への連絡方法》</div>
          <p>
            当社は、利用者に対し必要に応じて会員登録時に取得した電子メールアドレス、住所、電話番号等を利用して連絡できるものとし、いずれかの方法により連絡するかについては、当該連絡を行う当社の判断によるものとします。
          </p>
        </div>
        <div class="mb-48">
          <div class="text-bold">《個人情報の取り扱いについて》</div>
          <p>
            当社は、利用者の個人情報を別途定める「個人情報保護方針」に従い取り扱うものとし、利用者は、これに同意するものとします。
          </p>
        </div>
        <div class="mb-48">
          <div class="text-bold">《禁止事項》</div>
          <p>
            当社は、利用者による以下の各号に定める行為又は該当すると当社が判断する行為を禁止します。
          </p>
          <ol class="ml-24 mb-16">
            <li>
              当社、他の利用者もしくは第三者の権利又は法律上の利益（著作権、特許権、営業秘密、財産権、名誉権、プライバシー権等を含むがこれに限られない）を侵害する又は侵害するおそれのある行為
            </li>
            <li>
              当社、他の利用者もしくは第三者に損害を与える行為、またはそれらのおそれのある行為
            </li>
            <li>
              当社、他の利用者もしくは第三者を誹謗中傷し、信用を毀損する行為
            </li>
            <li>当社の事前の同意なくして本サービスを第三者に利用させる行為</li>
            <li>
              本サービスを、法令に違反する行為、犯罪行為、公序良俗に反する行為に利用する行為
            </li>
            <li>
              自ら、又は第三者をして、同一利用者のためのID及びパスワードを、当社の承諾なく、複数取得する行為
            </li>
            <li>事実に反する、またはそのおそれのある情報を提供する行為</li>
            <li>本サービスの運営を妨げる行為、またはそのおそれのある行為</li>
            <li>
              ID及びパスワードを不正に使用する行為（自己に発行されたID及びパスワード以外のID及びパスワードを使用する場合を含むがこれに限られない）。
            </li>
            <li>
              当社の書面による事前同意なくしてID及びパスワードを第三者に貸与する行為
            </li>
            <li>
              本サービスに関し、コンピューターウィルス等の有害なプログラムを使用ないし提供する行為
            </li>
            <li>
              公序良俗に反する行為もしくはそのおそれのある行為、または公序良俗に反する情報を他の利用者もしくは第三者に提供する行為
            </li>
            <li>法令等に違反する、または違反するおそれのある行為</li>
            <li>その他、当社が不適切と判断する行為</li>
          </ol>
          <p>
            当社は、利用者が前項各号の一の行為に該当すると判断した場合には、事前に通知することなく、当該利用者に対し、本サービスの利用停止、当該利用者の会員資格の取消しを行うことができるものとし、これにより当該利用者に何らかの損害が生じたとしても、弊社は一切責任を負わないものとします。
          </p>
        </div>
        <div class="mb-48">
          <div class="text-bold">《免責事項》</div>
          <p>
            当社は、本規約の別段の定め又はその他利用規約等に定める免責事項に該当したことによるほか、次の場合には免責されるものとします。
          </p>
          <ul class="ml-24 mb-16">
            <li>
              当社は、利用者が本サービスをご利用されたこと、又はご利用になれなかったことにより発生した一切の損害について、当社に責がある場合を除き、いかなる責任も負わないものとします。また、当社の故意又は重過失の場合を除き、社会通念上、債務不履行又は不法行為により通常発生するものと考えられる損害（いわゆる通常損害）を超える損害については責任を負わないものとします。
            </li>
            <li>
              当社は、利用者の受けた損害又は不利益が、本サービスのご利用にあたり利用者から提供いただいた情報に従い事務を処理したことに起因する場合には免責されるものとします。
            </li>
            <li>
              当社は、利用者が誤った情報を登録又は提供したことによって発生した損害については、いかなる責任も負わないものとします。
            </li>
            <li>
              当社は、本サービスで提供する情報や会場等の説明については、できる限り正確性を期しておりますが、正確性、完全性、有用性、最新性等に一切誤りがないことを保証するものではありません。現状と異なる表記があった場合、現状が優先されるものとします。
            </li>
            <li>
              当社は、本サービスからリンクされている第三者の各サイト及び本サービスへリンクしている第三者の各サイトのコンテンツ、サービス、商品等について一切関知しておりません。利用者ご自身の判断でご利用ください。
            </li>
            <li>
              利用者が、本サービスをご利用になることにより、他の利用者又は第三者に対して損害等を与えた場合には、当該利用者は自らの責任と費用において当該問題を解決し、当社及び会場に一切迷惑を及ぼさないものとします。
            </li>
            <li>
              天変地異、システムや通信回線等の障害、法令に基づく規則又は公的機関による命令等、及びその他同様の不可抗力の事態が生じた場合、それらの事態により本サービスの提供が不可能となることについて、当社はいかなる責任も負わないものとします。また、当該事態における本サービスの提供の中止又は中断、遅延等によって生じた利用者及び第三者の損害、不利益、及び逸失機会等についても、当社はいかなる責任も負わないものとします。
            </li>
            <li>
              当社は、本サービス又は本規約等の変更、追加、停止、中止、その他提供するサービスに関連して利用者及び第三者に生じた損害、不利益、及び逸失機会等について、本規約等又はその他利用規約等に明示的に定める場合を除き、いかなる責任も負わないものとします。
            </li>
          </ul>
        </div>
        <div class="mb-48">
          <div class="text-bold">《本規約等の変更》</div>
          <ul class="ml-24">
            <li>
              当社は、利用者に対する事前の通知なく本規約を改定できるものとし、本規約改定後は、改定後の本規約を適用するものとします。
              なお、利用者が本サービスを利用した場合、改定後の本規約に同意したものとします。
            </li>
            <li>
              当社は、その判断により利用者に事前に通知・連絡することなく、本サービスの全部または一部を変更・廃止することができるものとします。
            </li>
          </ul>
        </div>
        <div class="mb-48">
          <div class="text-bold">《附則》</div>
          <p>この規約は2020年4月1日から実施します。</p>
          <p>
            ※本規約は予告なく変更する場合もございますので、予めご了承ください。
          </p>
        </div>
      </div>
    </div>
    <div class="sub-footer">
      <router-link to="/">TIME SHARING（タイムシェアリング）</router-link>
    </div>
    <Footer />
    <ContactInfo />
  </div>
</template>

<script>
import Header from "@/client/organisms/LayoutHome/Header/Header.vue";
import Footer from "@/client/organisms/LayoutHome/Footer/Footer.vue";
import ContactInfo from "@/client/organisms/LayoutHome/ContactInfo/ContactInfo.vue";
export default {
  components: {
    Header,
    Footer,
    ContactInfo,
  },
};
</script>

<style lang="scss" scoped>
.register-page {
  .body {
    padding: 72px 10px;
    width: 100%;
    max-width: 1200px;
    margin: auto;

    .title {
      padding: 0 12px 16px 12px;
      margin: 1em 0 1.5em;
      border-bottom: 5px solid #1d9fc4;
      font-size: 24px;
    }
    .content {
      font-size: 16px;
      line-height: 24px;
      margin: 0 24px;
      .mb-16 {
        margin-bottom: 16px !important;
      }
      .mb-48 {
        margin-bottom: 48px;
        .ml-24 {
          margin-left: 24px;
        }
      }
      .text-bold {
        font-weight: bold;
        margin-bottom: 12px;
      }
      ul {
        margin-left: 8px;
      }
      ul > li {
        margin-bottom: 12px;

        ol {
          margin-top: 12px;
        }
      }
    }
  }
  .sub-footer {
    font-size: 10px;
    width: 100%;
    background: #f3f3f3;
    text-align: center;
    padding: 4px 0;
    a {
      text-decoration: none;
    }
  }
}
</style>
