import { getToken, removeToken } from "@/client/utils/authToken";
import { ROUTER_PATH, TYPE_USER } from "@/client/const";
import useAuth from "@/client/store/auth";

export function auth(context, type) {
  const { next } = context;
  const token = getToken(type);
  const authState = useAuth();
  if (token) {
    authState.isAuthenticated = true;
    return next();
  }
  authState.isAuthenticated = false;
  removeToken(TYPE_USER.USER);
  const redirectParameters = {
    path: context.to.fullPath
  };
  sessionStorage.setItem(
    "redirectParameters",
    JSON.stringify(redirectParameters)
  );

  next({ path: ROUTER_PATH.LOGIN });
}

export function guest(context, type) {
  const { next } = context;
  const token = getToken(type);
  const authState = useAuth();
  if (!token) {
    authState.isAuthenticated = false;
    return next();
  }
  if (type === TYPE_USER.USER) next(ROUTER_PATH.MY_PAGES + '/' + ROUTER_PATH.RESERVATIONS);
}
