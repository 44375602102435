<template>
  <div id="nav-icon1" @click="handleToggleClass" :class="isOpen ? 'open' : ''">
    <span></span>
    <span></span>
    <span></span>
    <p id="nav-txt">メニュー</p>
  </div>
</template>
<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";

const props = defineProps({
  openState: {
    type: Boolean,
  },
});
const emit = defineEmits(["toggle"]);
const isOpen = ref(false);
watch(props, (value) => (isOpen.value = JSON.parse(JSON.stringify(value.openState))));
const handleToggleClass = async () => {
  isOpen.value = !isOpen.value;
  emit("toggle", isOpen.value);

  let menu = document.getElementById("nav-txt")
  if(menu.textContent == "メニュー"){
    menu.textContent = "閉じる";
  } else {
    menu.textContent = "メニュー";
  }
};
</script>
<style lang="scss">
#nav-icon1 {
  width: 85%;
  height: 100%;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: end;
  justify-content: center;
  margin: 0 auto;

  p{
    text-align: center;
    font-size: 8px;
    white-space: nowrap;

    @media screen and (min-width: 600px){
      font-size: 13px;
    }
  }
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #636363;
  border-radius: 5px;
  opacity: 1;
  left: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg) translateX(-50%);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 15%;
}

#nav-icon1 span:nth-child(2) {
  top: 35%;
}

#nav-icon1 span:nth-child(3) {
  top: 55%;
}

#nav-icon1.open span:nth-child(1) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 35%;
  left: 0;
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -40px;
}

#nav-icon1.open span:nth-child(3) {
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  top: 35%;
  left: 0;
}
#nav-icon1.open {
  background-color: rgba(3, 3, 3, 0.1);
}

@media screen and (min-width: 576px) {

  #nav-icon1.open span:nth-child(1){
    transform: rotate(145deg);
    top: 40%;
  }

  #nav-icon1.open span:nth-child(3){
    transform: rotate(-145deg);
    top: 40%;
  }
}
</style>
