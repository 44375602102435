import api from '../http-request'

const config = {
    headers: {
        accept: "application/json",
        'content-type': 'multipart/form-data'
      }
};
// [POST METHOD]
export const sendContactApi = async (data) => {
    api.post(`api/contact/form`, data, config);
}
export const sendContactSpaceApi = async (data) => {
    api.post(`api/contact/form/space`, data, config);
}