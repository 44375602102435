<template>
  <v-card class="px-2 py-2 h-screen">
    <div class="modal-header">
        <v-card-title>日時を選ぶ</v-card-title>
        <button 
            @click="handleClosePopUp" 
            type="button" 
            class="btn-close_popup"
        >
        <i class="fa-solid fa-xmark" style="color: #2892D7;"></i>
        </button>
    </div>
    <div class="modal-content">    
        <v-card class="card-text">
            <div class="c-popup-calendar-inner">
                <div class="c-popup-calendar-left">
                    <FullCalendar v-bind:options="options" @click="handleDisplayBtnPrev"/>
               </div>
                <div class="c-popup-calendar-right">
                    <h3 class="title">カレンダーで利用日を選択してください。</h3>
                   <div v-if="props.dataPopup?.date">
                        <div class="date-client-select">
                            <span class="text">選択中</span>
                            <span id="value-date-popup" class="value">{{dateSelect}}</span>
                        </div>
                   </div>
                   <div v-else>
                        <div class="date-client-select d-none">
                            <span class="text">選択中</span>
                            <span id="value-date-popup" class="value"></span>
                        </div>
                   </div>
                    <div class="right-content">
                        <h3 class="title">利用時間を選択してください</h3>
                        <div class="group-time">
                            <div class="time-start">
                                <label for="time-start">開始時間</label>
                                <select @change="checkActiveBtnApply" id="time-start-popup" class="disable" name="time-start" disabled v-model="dataTime.timeStart">
                                    <option class="option-disabled" value="" selected disabled hiden>---</option>
                                    <option 
                                        :value="hourAM" 
                                        v-for="hourAM in convertedTimesAM" 
                                        :key="hourAM"
                                    >
                                        午前{{ hourAM }}
                                    </option>
                                    <option value="" disabled>---</option>
                                    <option 
                                        :value="hourPM" 
                                        v-for="hourPM in convertedTimesPM" 
                                        :key="hourPM"
                                    >
                                        午後{{ hourPM }}
                                    </option>
                                </select>
                            </div>
                            <div class="icon-right">
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>
                            <div class="time-end">
                                <label for="time-end">終了時間</label>
                                <select @change="checkActiveBtnApply" id="time-end-popup" class="disable" name="time-end"  disabled v-model="dataTime.timeEnd">
                                    <option class="option-disabled" value="" selected disabled hidden>---</option>
                                    <option 
                                        :value="hourAM" 
                                        v-for="hourAM in convertedTimesAM" 
                                        :key="hourAM"
                                    >
                                        午前{{ hourAM }}
                                    </option>
                                    <option value="" disabled>---</option>
                                    <option 
                                        :value="hourPM" 
                                        v-for="hourPM in convertedTimesPM" 
                                        :key="hourPM"
                                    >
                                        午後{{ hourPM }}
                                    </option>
                                    <!-- <option value="" disabled>---</option>
                                    <option 
                                        v-for="tomorrowHours in convertTomorrowHours" 
                                        :value="tomorrowHours.value" 
                                        :key="tomorrowHours.hour"
                                        class="option-auto-block"
                                    >
                                        翌午前{{ tomorrowHours.hour }}
                                    </option> -->
                                    <!-- <option class="option-auto-block" value="36:00">翌午前12:00</option> -->
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </div>


    <div class="modal-bottom">
        <button @click="handleApply" class="c-btn-apply" :disabled="isActive">適用する</button>
    </div>
  </v-card>
</template>

<script setup>
/* eslint-disable */
    import '@/client/components/SearchComponent/ModalCalendar.scss';
    import { defineEmits, reactive, ref, defineProps, onMounted} from "vue";
    import '@/client/components/SearchComponent/ModalCalendar.scss';
    import '@fullcalendar/core';
    import FullCalendar from '@fullcalendar/vue3';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import moment from "moment";

    const dataTime = ref({
        timeStart: '',
        timeEnd: '',
    })

    const emit = defineEmits([
        "handleCloseModal",
        "selectDate",
        "selectTimeStart",
        "selectTimeEnd",
        "handleSelectFullDateTime",
    ]);    
    const handleClosePopUp = () => {
        emit("handleCloseModal", 123);
    };

    const props = defineProps({
        dataPopup: {
            type: Object,
            default: () => {},
        },
    });

    const dateSelect = props.dataPopup?.date.replaceAll('-','/')

    const options = reactive({
        locale: 'ja',
        defaultDate: '',
        headerToolbar: {
            left:   'prev',
            center: 'title',
            right:  'next'
        },
        plugins: [dayGridPlugin, interactionPlugin],
        interactionPlugin: 'dayGridMonth',
        selectable: false,
        displayEventTime: false,
        // validRange: {
        //     end: new Date(today.getFullYear(), today.getMonth() + 2, 0)
        // },
        dateClick: function(info) {
            const date = info.dateStr;
            const dateParts = date.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]);
            const day = parseInt(dateParts[2]);
            const dayBook = year + '/' + month + '/' + day;

            if(moment(info.date).diff(moment(new Date()), 'day') >= 0) {
                const valueEl = document.querySelector('#value-date-popup');
                const dateClinetSelect = document.querySelector('.date-client-select');
                dateClinetSelect.classList.remove("d-none");
                valueEl.textContent = dayBook;
                info.dayEl.style.backgroundColor = '#bce8f14d';
                const dayEls = document.querySelectorAll('.fc-day');
                for (let i = 0; i < dayEls.length; i++) {
                    if (dayEls[i] !== info.dayEl) {
                        dayEls[i].style.backgroundColor = 'unset';
                        dayEls[i].classList.remove("day-select");
                    }
                }
                const timeStart = document.getElementById('time-start-popup');
                timeStart.removeAttribute("disabled");
                timeStart.classList.remove("disable");
                const timeEnd = document.getElementById('time-end-popup');
                timeEnd.removeAttribute("disabled");
                timeEnd.classList.remove("disable");
            } else {
                info.dayEl.style.backgroundColor = 'unset';
                return false;
            }
        },
        dayCellDidMount: function(info){    
            const listDay = document.querySelectorAll(".fc-day");
            var day = moment(info.date).format('DD');
            var originElement = info.el.querySelectorAll(".fc-daygrid-day-number");
            originElement.forEach(e => e.classList.add("d-none")); 
            var targetElement = info.el.querySelectorAll(".fc-daygrid-day-top");
            targetElement.forEach(e => e.innerHTML = day );
            if(props.dataPopup?.date){
                for (let i = 0; i < listDay.length; i++) {
                    if (moment(listDay[i].getAttribute("data-date")).isSame(moment(props.dataPopup.date), 'day')) {
                        listDay[i].classList.add("day-select"); 
                    }
                }
                const timeStart = document.querySelector('#time-start-popup');
                const timeStartOptions = timeStart.options
                for(let i = 1; i < timeStartOptions.length; i ++) {
                    if (timeStartOptions[i].value === props.dataPopup?.timeStart) {
                        timeStartOptions[i].setAttribute("selected", true);
                    }
                }
                timeStart.removeAttribute("disabled");
                timeStart.classList.remove("disable");
                const timeEnd = document.querySelector('#time-end-popup');
                const timeEndOptions = timeEnd.options
                for(let i = 1; i < timeEndOptions.length; i ++) {
                    if (timeEndOptions[i].value === props.dataPopup?.timeEnd) {
                        timeEndOptions[i].setAttribute("selected", true);
                    }
                }
                timeEnd.removeAttribute("disabled");
                timeEnd.classList.remove("disable");
                checkActiveBtnApply();
            }
        },
        
    });

    const hoursAM = [];
    const hoursPM = [];
    // const tomorrowHours = [];
    for (let i = 0; i <= 11.5; i += 0.5) {
        if (i < 10) {
            hoursAM.push("0" + i + ":00");
        } else {
            hoursAM.push(`${i}:00`);
        }
    }

    // for (let i = 0; i <= 12; i += 0.5) {
    //     if (i < 10) {
    //         tomorrowHours.push({
    //             hour: "0" + i + ":00",
    //             value: (i + 24) + ":00",
    //         });
    //     } else {
    //         tomorrowHours.push({
    //             hour:  i + ":00",
    //             value: (i + 24) + ":00",
    //         });
    //     }
    // }

    for (let i = 12; i <= 24; i += 0.5) {
        hoursPM.push(`${i}:00`);
    }

    const convertTimeAM = (hoursAM) => {
        if (hoursAM.includes(".5")) {
            return hoursAM.replace(".5:00", ":30");
        }
        return hoursAM;
    };

    const convertTimePM = (hoursPM) => {
        if (hoursPM.includes(".5")) {
            return hoursPM.replace(".5:00", ":30");
        }
        return hoursPM;
    };

    // const convertTomorrowHours = tomorrowHours.map((obj) => {
    //     if (obj.hour.includes(".5")) {
    //         obj.hour = obj.hour.replace(".5:00", ":30");
    //     }

    //     if (obj.value.includes(".5")) {
    //         obj.value = obj.value.replace(".5:00", ":30");
    //     }

    //     return obj;
    // });

    const convertedTimesAM = hoursAM.map(convertTimeAM);
    const convertedTimesPM = hoursPM.map(convertTimePM);

    //check disable button apply    
    const isActive = ref(true)
    const checkActiveBtnApply = () => {
        const timeEnd = document.getElementById('time-end-popup').value;
        const timeStart = document.getElementById('time-start-popup').value;

        if(timeEnd !== '' && timeStart !== '' ) {
            isActive.value = false
            dataTime.value.timeEnd = timeEnd
            dataTime.value.timeStart = timeStart
            if( dataTime.value.timeEnd <= dataTime.value.timeStart) {
                dataTime.value.timeEnd = ''
            }
        }

        const timeEndPopup = document.getElementById('time-end-popup');
        const options = timeEndPopup.getElementsByTagName('option');
        if(timeStart !== '') {
            for (const option of options) {
                if (option.value < timeStart + 1) {
                    option.style.display = 'none';
                } else {
                    option.style.display = 'block';
                }
               option.value === "" ? option.style.display = 'block': '';
            }
        }
    };

    const handleApply = () => {
        const btnRevemoValueDate = document.querySelector(".search-content-calendar-btn-reset");
        btnRevemoValueDate.classList.add('active');
        const valueDate = document.getElementById("value-date-popup").innerText;
        const timeStart = document.getElementById("time-start-popup").value;
        const timeEnd = document.getElementById("time-end-popup").value;
        const selectCalendar = document.querySelector("#select-calendar");
        const fullDateTime = valueDate + ' ' + timeStart + '～' + timeEnd;
        selectCalendar.value = fullDateTime;
        emit('selectDate', valueDate);
        emit('selectTimeStart', timeStart);
        emit('selectTimeEnd', timeEnd);
        emit('handleSelectFullDateTime',fullDateTime)
        handleClosePopUp()
    };
    
    const handleDisplayBtnPrev = () => {
        const activeDate = document.getElementsByClassName('fc-toolbar-title')?.[0]
        const prv = document.getElementsByClassName("fc-toolbar-chunk")?.[0]

        if(activeDate && prv) {
            const nowMonth = moment().format('YYYY年MM月')
            const activeMonth = moment(activeDate.innerText,'YYYY年MM月').format('YYYY年MM月')
            const prevButton = prv.getElementsByTagName('button')?.[0]
            if(nowMonth === activeMonth ) {
                if(prevButton) {
                    prevButton.disabled = true;
                    prv.style.opacity = 0
                }
            }else { 
                if(prevButton) {
                    prevButton.disabled = false;
                    prv.style.opacity = 1
                }
            }
        }
    }

    onMounted(() => {
        handleDisplayBtnPrev();
    });
</script>

<style lang="scss" scoped>


@media (max-width: 767px) {
    .h-screen {
        max-height: 80dvh !important;
    }
    .c-popup-calendar .modal-content .v-card {
        overflow-y: auto;
        padding-bottom: 30px !important;
    }
}
</style>
