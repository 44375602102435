<template>
  <div class="drawer-menu">
    <div class="drawer-menu__header">
      <div class="logo-timeshare">
        <img :src="time_sharing_logo" class="logo" width="244" height="49" />
      </div>
    </div>
    <BlockMenu v-for="block in menuData" :key="block.title" :menuInfo="block" />
    <div class="drawer-menu__footer">
      <div
        v-if="isAuthenticated"
        class="my-page w-100"
        @click="handlePush(ROUTE_NAME.CUSTOMER.MY_PAGE)"
      >
        マイページ
      </div>
      <div v-else class="auth-group w-100 d-flex">
        <div class="w-50">
          <div class="login" @click="handlePush(ROUTE_NAME.CUSTOMER.LOGIN)">
            ログイン
          </div>
        </div>
        <div class="w-50">
          <div
            class="register"
            @click="handlePush(ROUTE_NAME.CUSTOMER.REGISTER)"
          >
            新規登録
          </div>
        </div>
      </div>
    </div>
    <InquiriesDialog
      :showDialog="isShowDialog"
      @closeDialog="isShowDialog = false"
    />
  </div>
</template>
<script setup>
import time_sharing_logo from "@/client/assets/images/timesharing.svg";
import BlockMenu from "./BlockMenu.vue";
import InquiriesDialog from "@/client/organisms/LayoutHome/ContactInfo/InquiriesDialog.vue";
import { ROUTE_NAME, TYPE_USER } from "@/client/const";
import useAuth from "@/client/store/auth";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { getToken } from "@/client/utils/authToken";


const token = getToken(TYPE_USER.USER);
const store = useAuth();
const router = useRouter();
const { isAuthenticated } = storeToRefs(store);
const isShowDialog = ref(false);
const menuData = [
  {
    title: "スペースを探す",
    buttons: [
      {
        description: " エリアから探す",
        routeName: ROUTE_NAME.CUSTOMER.CONTENT_LOCATION_HEADER,
        icon: "fa-map-marked-alt"
      },
      {
        description: " 利用用途から探す",
        routeName: ROUTE_NAME.CUSTOMER.CONTENT_CASE,
        icon: "fa-filter"
      },
    ],
  },
  {
    title: "TIME SHARINGについて",
    buttons: [
      {
        description: "パーティー会場",
        routeName: ROUTE_NAME.CUSTOMER.FOR_PARTY,
        icon: "fa-solid fa-champagne-glasses"
      },
      {
        description: "はじめてのタイムシェアリング",
        routeName: ROUTE_NAME.LP.HOW_TO_USE,
        icon: "fa-star"
      },
      {
        description: "ケータリング",
        routeName: ROUTE_NAME.LP.CATERING,
        icon: "fa-utensils"
      },
      {
        description: "スペース安心補償",
        routeName: ROUTE_NAME.LP.SPACE_SAFETY,
        icon: "fa-house-chimney-medical"
      },
      {
        description: "法人利用",
        routeName: ROUTE_NAME.LP.CORPORATE_USE,
        icon: "fa-user-tie"
      },
      {
        description: "よくある質問",
        routeName: ROUTE_NAME.CUSTOMER.USE_GUIDE,
        icon: "fa-circle-question"
      },
    ],
  },
];

if (token) {
  store.isAuthenticated = true;
}

const handlePush = (name) => router.push({ name });
</script>
<style lang="scss">
.drawer-menu {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #0b85aa 30%, #0a50c9 100%);
  overflow-y: auto;
  color: #fff;
  &__header {
    background: #fff;
    padding: 10px 0;
  }
  &__footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    margin: 40px auto 0px auto;
    padding: 20px 5%;
    // position: absolute;
    // bottom: 0;
    .login,
    .register,
    .inquiries,
    .my-page {
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(10, 80, 201, 0.8) 100%
      );
      border-radius: 8px;
      font-weight: bold;
      margin: 5px;
      padding: 10px 5px;
      font-size: 18px;
      display: block;
      text-align: center;
      box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);
    }
    .inquiries {
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(86, 216, 157, 0.8) 100%
      );
    }
  }
}
</style>
