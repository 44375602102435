<template>
  <div v-if="showButtonDotted === true">
    <router-link :to="buttonStyle.link" class="button-dotted">
      <span>{{ buttonStyle.title }}</span>
      <v-icon v-if="buttonStyle.icon" class="button-dotted__icon" small>{{
        buttonStyle.icon
      }}</v-icon>
    </router-link>
  </div>
  <div
    v-else
    v-bind:style="{ 'background-color': buttonStyle.background }"
    class="button"
  >
    <div v-if="buttonStyle.icon">
      <i
        v-if="buttonStyle.id === 3"
        :class="buttonStyle.icon"
        class="icon-button"
      ></i>
      <v-icon v-else class="button__icon" small>{{ buttonStyle.icon }}</v-icon>
    </div>

    <span>{{ buttonStyle.title }}</span>
  </div>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    buttonStyle: {
      type: Object,
      default: () => {},
    },
    showButtonDotted: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  margin: 15px 0px 15px 10px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
}
.button__icon {
  margin-right: 3px;
}
.button-dotted {
  font-size: 14px;
  border-bottom: 1.5px dotted black;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  padding: 3px 3px;
  margin-right: 15px;
}

.button-dotted:hover {
  color: #0056b3;
}
.button-dotted__icon {
  margin-left: 3px;
}
.icon-button {
  margin-right: 8px;
}
</style>
